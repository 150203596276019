import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FullServiceService } from '../../../services/full-service.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-send-to-users',
  templateUrl: './send-to-users.component.html',
  styleUrls: ['./send-to-users.component.scss']
})
export class SendToUsersComponent {

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailList: File = null;

  form: UntypedFormGroup;
  page = 1;
  searchText = '';
  data: any[] = [];
  itemsTotal = 0;
  pagesTotal = 0;
  pageLimit = 0;
  currentPage = 1;
  showSpiner = true;
  deleted = '';

  users: any[] = [];
  dropdownPages = [];

  body = {
    role: 'user'
  }

  constructor(
    public dialog: MatDialog,
    private full: FullServiceService,
    private toastr: ToastrManager,
    private userService: UserService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      body: new UntypedFormControl('', [Validators.required]),
      // image: new UntypedFormControl('', [Validators.required]),
      data: new UntypedFormControl(null, []),
      receivers: new UntypedFormControl([], [Validators.compose([Validators.required, Validators.minLength(1)])]),
    });

    this.getAllUsers();
  }

  sendFcm() {
    this.full.sendFirebaseUsersNotification(this.form.value).subscribe(datqa => {
      this.toastr.successToastr(this.translate.instant('fcmSentSuccessfully'));
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  getAllUsers() {
    this.userService.getAllUsersWithSearch(this.currentPage, 250, this.searchText, this.body)
      .subscribe((data: any) => {
        // this.form.patchValue({
        //   receivers: ['60fbcdcb40549372c1595fa1', '60f3a5b073b5b05fcb824ede']
        // })
        this.dropdownPages = Array.from({ length: data.pageCount }, () => 1);
      });
  }

  selectPage(event) {
    this.userService.getAllUsersWithSearch(event.value, 250, this.searchText, this.body)
      .subscribe((data: any) => {
        let recs = [];
        data.data.map(e => {
          recs.push(e._id)
        })
        this.form.patchValue({
          receivers: recs
        })
        this.dropdownPages = Array.from({ length: data.pageCount }, () => 1);
      });
    this.page = event.value;
  }
}
