import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FullServiceService } from '../../../services/full-service.service';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-send-to-topic',
  templateUrl: './send-to-topic.component.html',
  styleUrls: ['./send-to-topic.component.scss']
})
export class SendToTopicComponent {

   progress = 0;
    thumbnailFileList = [];
    thumbnailUrlList: string;
    thumbnailList: File = null;
  
    form: UntypedFormGroup;
    page = 1;
    searchText = '';
    data: any[] = [];
    itemsTotal = 0;
    pagesTotal = 0;
    pageLimit = 0;
    currentPage = 1;
    showSpiner = true;
    deleted = '';
  
    users: any[] = [];
    topicTypes = [{
      _id: 'all_users',
      label: 'allUsers'
    }];
  
    body = {
      role: 'user'
    }
  
    constructor(
      public dialog: MatDialog,
      private full: FullServiceService,
      private toastr: ToastrManager,
      private userService: UserService,
      private translate: TranslateService,
    ) { }
  
    ngOnInit() {
      this.form = new UntypedFormGroup({
        title: new UntypedFormControl('', [Validators.required]),
        body: new UntypedFormControl('', [Validators.required]),
        // image: new UntypedFormControl('', [Validators.required]),
        topic: new UntypedFormControl('all_users', [Validators.required]),
        data: new UntypedFormControl(null, []),
      });
    }
  
    sendFcm() {
      this.full.sendFirebaseTopicNotification(this.form.value).subscribe(datqa => {
        this.toastr.successToastr(this.translate.instant('fcmSentSuccessfully'));
      }, err => {
        this.toastr.errorToastr(err);
      })
    }
    
    thumbnailSelectFile(event) {
      this.thumbnailFileList = [];
      this.thumbnailList = event.target.files[0];
      this.thumbnailFileList.push(this.thumbnailList);
      this.thumbnailUpload();
    }
  
    thumbnailUpload() {
      let fd = new FormData();
  
      fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
      this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              image: event.body.images[0]
            });
            this.thumbnailUrlList = event.body.images[0];
        }
      }, err => {
        this.thumbnailUrlList = this.translate.instant('imageNotUploaded');
      });
      this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
    }
  
    deleteUploadedThumbnailImage() {
      this.form.patchValue({
        image: null
      });
      this.progress = 0;
      this.thumbnailFileList = [];
    }
}
