import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Package } from '../../../models/category';
import { FullServiceService } from '../../../services/full-service.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-change-index-modal',
  templateUrl: './change-index-modal.component.html',
  styleUrls: ['./change-index-modal.component.scss']
})
export class ChangeIndexModalComponent {
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailList: File = null;

  form: UntypedFormGroup;
  id: string;
  modelName: string;
  index: number;
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ChangeIndexModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    this.id = this.data.id;
    this.modelName = this.data.model;
    this.index = +this.data.index;
    console.log('sd', this.data);
    
    this.form = new UntypedFormGroup({
      index: new UntypedFormControl(this.index, [Validators.compose([Validators.required, Validators.min(0)])]),
    });
  }

  changeIndex() {
    this.full.changeIndexToModel(this.modelName, this.id, this.form.value).subscribe((resChangeIndex: any) => {
      if(resChangeIndex.state){
        this.toastr.successToastr(this.translate.instant('changeSuccessfully'));
        this.dialogRef.close(true);
      }
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
