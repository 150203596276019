<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewSlider' | translate}}</h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editSlider' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <mat-radio-group aria-label="Select an Type" formControlName="type"  (change)="changeType()">
                                <mat-radio-button value="image">Image</mat-radio-button>
                                <mat-radio-button value="video">Video</mat-radio-button>
                            </mat-radio-group>                          
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                          <h5 color="primary">
                            <span>{{ "video" | translate }}</span>
                          </h5>
                          <label *ngIf="thumbnailFileListV.length > 0" class="upload-label" for="upload-photo" appDrag>
                            <video controls width="640px" height="267px" controls>
                              <source src="{{thumbnailUrlListV}}" type="video/mp4"  />
                              <source src="{{thumbnailUrlListV}}" type="video/webm"  />
                            </video>
                            <i class="material-icons pointer close-icon" (click)="deleteUploadedVideo()"> close </i>
                          </label>
                          <label *ngIf="thumbnailFileListV.length === 0" class="upload-label" for="upload-thumbnail-mp4" appDrag >
                            <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                            <input type="file" class="upload-thumbnail-id" accept="video/mp4,video/x-m4v,video/*" (change)="videoSelectFile($event)" name="my-video" id="upload-thumbnail-mp4" />
                          </label>
                          <br />
                          <br />
                        </div>
                      </div>
                    <div class="row text-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'image' | translate}}</span>
                            </h5>
                            <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer" (click)="deleteUploadedThumbnailImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail"
                                appDrag>
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="thumbnailSelectFile($event)" name="photo" accept="image/*" id="upload-thumbnail" />
                            </label>
                        </div>
                    </div>
                   
                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewSlider' | translate}}</span>
                                <span *ngIf="objectId">{{'editSlider' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>