<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{'sendFcm' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-center">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]="form">
                    <!-- <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progress" ></div>
                    </div>
                    <div class="row text-center">
                    <div class="col">
                        <h5 color="primary">
                        <span>{{ "image" | translate }}</span>
                        </h5>
                        <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag >
                        <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer" />
                        <i class="material-icons pointer close-icon" (click)="deleteUploadedThumbnailImage()"> close</i>
                        </label>
                        <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag >
                        <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                        <input type="file" (change)="thumbnailSelectFile($event)" name="photo" id="upload-thumbnail" />
                        </label>
                        <br />
                        <br />
                    </div>
                    </div> -->
                    <div class="row">
                        
                        <div class="col-12">
                            <div class="example-container">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="{{ 'topicsTypes' | translate }}" formControlName="topic">
                                        <mat-option *ngFor="let topic of topicTypes; let i = index" value="{{topic._id}}">{{ topic.label | translate }} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="example-container">
                                <mat-form-field class="full-width example-full-width">
                                    <span>{{'title' | translate}}</span>
                                    <input matInput formControlName="title" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="example-container">
                                <mat-form-field class="full-width example-full-width">
                                    <span>{{'body' | translate}}</span>
                                    <textarea rows="5" matInput formControlName="body" type="text"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button color="warn" (click)="sendFcm()" [disabled]="!form.valid">
                                {{'sendFcm' | translate}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>