import { AddFaqComponent } from "./add-faq/add-faq.component";
import { FaqComponent } from "./faq/faq.component";
import { AddSliderComponent } from "./sliders/add-slider/add-slider.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ToastrModule } from "ng6-toastr-notifications";

// Translate Module
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// Interceptors
import { ErrorInterceptor } from "./../helpers/error.interceptor";
import { JwtInterceptor } from "./../helpers/jwt.interceptor";

// Angular Material
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
// import { MatNativeDateModule } from '@angular/material';
// import { MatMomentDateModule } from "@angular/material-moment-adapter";

// Components
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormsModule } from "ng2-validation";
import { BlogCategoriesComponent } from "./blog/blog-categories/blog-categories.component";
import { ArticlesComponent } from "./blog/articles/articles.component";
import { AddBloCategoryComponent } from "./blog/add-blo-category/add-blo-category.component";
import { CommentManagementComponent } from "./blog/comment-management/comment-management.component";
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
// Dialogs
import { ImageComponent } from "./modals/image/image.component";
import { ClipboardModule } from "@angular/cdk/clipboard";

// Directives
import { DragDirective } from "./directives/dragDrop.directive";
import { UsersListComponent } from "./userManagement/users-list/users-list.component";
import { AddUserComponent } from "./userManagement/add-user/add-user.component";
import { ContactsComponent } from "./contactsManagement/contacts/contacts.component";
import { EditCommentComponent } from "./blog/edit-comment/edit-comment.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UploadFilesComponent } from "./uploading-center/upload-files/upload-files.component";
import { UploadersComponent } from "./uploading-center/uploaders/uploaders.component";
import { SliderListComponent } from "./sliders/slider-list/slider-list.component";
import { OffersListComponent } from "./offers/offers-list/offers-list.component";
import { AddOfferComponent } from "./offers/add-offer/add-offer.component";
import { ProfileComponent } from "./personal-info/profile/profile.component";
import { SettingsComponent } from "./settings/settings.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ChatComponent } from "./chat/chat.component";
import { ConversationsComponent } from "./chat/conversations/conversations.component";
import { AccountOrdersComponent } from "./account-orders/account-orders.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AuthorListComponent } from "./blog/author-list/author-list.component";
import { AddAuthorComponent } from "./blog/add-author/add-author.component";
import { MatChipsModule } from "@angular/material/chips";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { OurServicesComponent } from "./our-services/our-services.component";
import { AddNewServiceComponent } from "./our-services/add-new-service/add-new-service.component";
import { OurAgentsComponent } from "./our-agents/our-agents.component";
import { AddAgentComponent } from "./our-agents/add-agent/add-agent.component";
import { HomeVideosComponent } from "./home-videos/home-videos.component";
import { AddHomeVideoComponent } from "./home-videos/add-home-video/add-home-video.component";
import { PartnersComponent } from "./partners/partners.component";
import { AddPartnerComponent } from "./partners/add-partner/add-partner.component";
import { OurClientsComponent } from "./our-clients/our-clients.component";
import { AddClientComponent } from "./our-clients/add-client/add-client.component";
import { OrdersComponent } from "./orders/orders.component";
import { InquiriesComponent } from "./inquiries/inquiries.component";

import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { LoggerComponent } from "./logger/logger.component";
import { DevelopmentRequestComponent } from "./development-request/development-request.component";
import { AddCatRequestComponent } from "./add-cat-request/add-cat-request.component";
import { CitiesLisComponent } from "./cities/cities-lis/cities-lis.component";
import { AddCityComponent } from "./cities/add-city/add-city.component";
import { SendFcmComponent } from "./send-fcm/send-fcm.component";
import { SiteInfoComponent } from "./site-info/site-info.component";
import { FeaturesListComponent } from "./features/features-list/features-list.component";
import { AddFeatuerComponent } from "./features/add-featuer/add-featuer.component";
import { MessageComponent } from "./modals/message/message.component";
import { UserCommentsComponent } from "./userManagement/user-comments/user-comments.component";
import { GalleryComponent } from "./userManagement/gallery/gallery.component";
import { AddGalleryComponent } from "./userManagement/add-gallery/add-gallery.component";
import { VerifyRequestsComponent } from "./verify-requests/verify-requests.component";
import { LotListComponent } from "./lot-list/lot-list.component";
import { AddLotListComponent } from "./add-lot-list/add-lot-list.component";
import { LotSubscribersListComponent } from "./lot-subscribers-list/lot-subscribers-list.component";
import { SendSpecificNotiComponent } from "./send-specific-noti/send-specific-noti.component";
import { EndLotComponent } from "./end-lot/end-lot.component";
import { AddSpecialComponent } from "./specials/add-special/add-special.component";
import { SpecialsListComponent } from "./specials/specials-list/specials-list.component";
import { DiscountListComponent } from "./discounts/discount-list/discount-list.component";
import { AddDiscountComponent } from "./discounts/add-discount/add-discount.component";
import { EmptyValueUsersComponent } from "./userManagement/empty-value-users/empty-value-users.component";
import { AddClientListComponent } from "./clientsList/add-client-list/add-client-list.component";
import { ClientsListComponent } from "./clientsList/clients-list/clients-list.component";
import { InterestsListComponent } from "./interests/interests-list/interests-list.component";
import { AddInterestComponent } from "./interests/add-interest/add-interest.component";
import { CommunicationsAttepmtsListComponent } from "./communication-attmpts/communications-attepmts-list/communications-attepmts-list.component";
import { AddCommunicationsAttepmtComponent } from "./communication-attmpts/add-communications-attepmt/add-communications-attepmt.component";
import { AddClientOrderComponent } from "./clientsList/add-client-order/add-client-order.component";
import { ClientOrdersComponent } from "./clientsList/client-orders/client-orders.component";
import { JobsListComponent } from "./jobs/jobs-list/jobs-list.component";
import { AddJobComponent } from "./jobs/add-job/add-job.component";
import { ClientAddressComponent } from "./clientsList/client-address/client-address.component";
import { AddClientAddressComponent } from "./clientsList/add-client-address/add-client-address.component";
import { AddArticleComponent } from "./blog/add-article/add-article.component";
import { AddBankComponent } from "./bank-list/add-bank/add-bank.component";
import { BanksComponent } from "./bank-list/banks/banks.component";
import { AddOfficeComponent } from "./offices-list/add-office/add-office.component";
import { OfficeListComponent } from "./offices-list/office-list/office-list.component";
import { TransferListComponent } from "./transfers/transfer-list/transfer-list.component";
import { AddTransferComponent } from "./transfers/add-transfer/add-transfer.component";
import { TransferStatusComponent } from "./transfers/transfer-status/transfer-status.component";
import { TransferDetailesComponent } from "./transfers/transfer-detailes/transfer-detailes.component";
import { CodesComponent } from "./codeList/codes/codes.component";
import { AddCodeComponent } from "./codeList/add-code/add-code.component";
import { AddLessCatComponent } from "./less-cat/add-less-cat/add-less-cat.component";
import { LessCatListComponent } from "./less-cat/less-cat-list/less-cat-list.component";
import { AddUnitComponent } from "./units/add-unit/add-unit.component";
import { UnitsListComponent } from "./units/units-list/units-list.component";
import { LessonsListComponent } from "./lessons/lessons-list/lessons-list.component";
import { AddLessonComponent } from "./lessons/add-lesson/add-lesson.component";
import { AddExercisComponent } from "./exercises/add-exercis/add-exercis.component";
import { ExercisListComponent } from "./exercises/exercis-list/exercis-list.component";
import { AddBaccalaureateComponent } from "./baccalaureates/add-baccalaureate/add-baccalaureate.component";
import { BaccalaureatesListComponent } from "./baccalaureates/baccalaureates-list/baccalaureates-list.component";
import { AddBookComponent } from "./book/add-book/add-book.component";
import { BookListComponent } from "./book/book-list/book-list.component";
import { ExamsListComponent } from "./exam/exams-list/exams-list.component";
import { AddExamComponent } from "./exam/add-exam/add-exam.component";
import { AddQuestionComponent } from "./questions/add-question/add-question.component";
import { QuestionsListComponent } from "./questions/questions-list/questions-list.component";
import { AddQuestionModalComponent } from "./questions/add-question-modal/add-question-modal.component";
import { ExamQuestionsComponent } from "./exam/exam-questions/exam-questions.component";
import { NgChartsModule } from "ng2-charts";
import { AddPackageComponent } from "./packages/add-package/add-package.component";
import { PackagesListComponent } from "./packages/packages-list/packages-list.component";
import { PaymentMethodsListComponent } from "./payment-methods/payment-methods-list/payment-methods-list.component";
import { AddPaymentMethodsComponent } from "./payment-methods/add-payment-methods/add-payment-methods.component";
import { AddFeatureComponent } from "./features/add-feature/add-feature.component";

import { MatStepperModule } from "@angular/material/stepper";
import { MatNativeDateModule } from "@angular/material/core";
import { RequestPackagesListComponent } from "./request-packages/request-packages-list/request-packages-list.component";
import { ViewRequestPackageComponent } from "./request-packages/view-request-package/view-request-package.component";
import { PackagesFeauturesListComponent } from "./packages-feautures/packages-feautures-list/packages-feautures-list.component";
import { AddPackagesFeautureComponent } from "./packages-feautures/add-packages-feauture/add-packages-feauture.component";
import { UserListComponent } from "./users/user-list/user-list.component";
import { ApiKeyInterceptor } from "../helpers/api-key.interceptor";
import { SecurePipe } from "../helpers/source.pipe";
import { AddPackageToUserComponent } from "./users/add-package-to-user/add-package-to-user.component";
import { ForgotPasswordListComponent } from "./users/forgot-password-list/forgot-password-list.component";
import { StuffListComponent } from "./users/stuff-list/stuff-list.component";
import { AddEmployeeComponent } from "./users/add-employee/add-employee.component";
import { ChangePasswordModalComponent } from "./users/change-password-modal/change-password-modal.component";
import { DeleteAccountRequestsComponent } from './users/delete-account-requests/delete-account-requests.component';
import { FileSaverModule } from "ngx-filesaver";
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { AddNoteToUserComponent } from './users/add-note-to-user/add-note-to-user.component';
import { ChangeIndexModalComponent } from './modals/change-index-modal/change-index-modal.component';
import { ChangeBranchModalComponent } from './modals/change-branch-modal/change-branch-modal.component';


import {MatRadioModule} from '@angular/material/radio';
import { AddBriefLessonComponent } from './brief-lessons/add-brief-lesson/add-brief-lesson.component';
import { BriefLessonsListComponent } from './brief-lessons/brief-lessons-list/brief-lessons-list.component';
import { AddMaterialFilesComponent } from './material-files/add-material-files/add-material-files.component';
import { MaterialFilesListComponent } from './material-files/material-files-list/material-files-list.component';
import { AddFileModalComponent } from './material-files/add-file-modal/add-file-modal.component';
import { SendToTopicComponent } from './firebase-notifications/send-to-topic/send-to-topic.component';
import { SendToUsersComponent } from './firebase-notifications/send-to-users/send-to-users.component'; 
// Translate Function
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BlogCategoriesComponent,
    ArticlesComponent,
    AddBloCategoryComponent,
    AddArticleComponent,
    CommentManagementComponent,
    FaqComponent,
    AddFaqComponent,

    // Directives
    DragDirective,
    // Dialogs
    ImageComponent,
    UsersListComponent,
    AddUserComponent,
    SliderListComponent,
    AddSliderComponent,
    ContactsComponent,
    EditCommentComponent,
    DashboardComponent,
    UploadFilesComponent,
    UploadersComponent,
    SliderListComponent,
    OffersListComponent,
    AddOfferComponent,
    ProfileComponent,
    SettingsComponent,
    AboutUsComponent,
    ChatComponent,
    ClientOrdersComponent,
    AddClientOrderComponent,
    AddBankComponent,
    BanksComponent,
    AddOfficeComponent,
    CodesComponent,
    AddCodeComponent,
    OfficeListComponent,
    TransferListComponent,
    AddTransferComponent,
    TransferStatusComponent,
    TransferDetailesComponent,
    ConversationsComponent,
    AccountOrdersComponent,
    AuthorListComponent,
    AddAuthorComponent,
    ConfirmDialogComponent,
    OurServicesComponent,
    AddNewServiceComponent,
    OurAgentsComponent,
    AddAgentComponent,
    HomeVideosComponent,
    AddHomeVideoComponent,
    PartnersComponent,
    AddPartnerComponent,
    OurClientsComponent,
    AddClientComponent,
    OrdersComponent,
    InquiriesComponent,
    LoggerComponent,
    DevelopmentRequestComponent,
    AddCatRequestComponent,
    CitiesLisComponent,
    AddCityComponent,
    SendFcmComponent,
    SiteInfoComponent,
    FeaturesListComponent,
    AddFeatuerComponent,
    MessageComponent,
    UserCommentsComponent,
    GalleryComponent,
    AddGalleryComponent,
    VerifyRequestsComponent,
    LotListComponent,
    AddLotListComponent,
    LotSubscribersListComponent,
    SendSpecificNotiComponent,
    EndLotComponent,
    AddSpecialComponent,
    SpecialsListComponent,
    DiscountListComponent,
    AddDiscountComponent,
    EmptyValueUsersComponent,
    AddClientListComponent,
    ClientsListComponent,
    InterestsListComponent,
    AddInterestComponent,
    CommunicationsAttepmtsListComponent,
    AddCommunicationsAttepmtComponent,
    AddClientOrderComponent,
    JobsListComponent,
    AddJobComponent,
    ClientAddressComponent,
    AddClientAddressComponent,
    AddLessCatComponent,
    LessCatListComponent,
    AddUnitComponent,
    UnitsListComponent,
    LessonsListComponent,
    AddLessonComponent,
    AddExercisComponent,
    ExercisListComponent,
    AddBaccalaureateComponent,
    BaccalaureatesListComponent,
    AddBookComponent,
    BookListComponent,
    ExamsListComponent,
    AddExamComponent,
    AddQuestionComponent,
    QuestionsListComponent,
    AddQuestionModalComponent,
    ExamQuestionsComponent,
    AddPackageComponent,
    PackagesListComponent,
    PaymentMethodsListComponent,
    AddPaymentMethodsComponent,
    AddFeatureComponent,
    RequestPackagesListComponent,
    ViewRequestPackageComponent,
    PackagesFeauturesListComponent,
    AddPackagesFeautureComponent,
    UserListComponent,
    SecurePipe,
    AddPackageToUserComponent,
    ForgotPasswordListComponent,
    StuffListComponent,
    AddEmployeeComponent,
    ChangePasswordModalComponent,
    DeleteAccountRequestsComponent,
    UserDetailsComponent,
    AddNoteToUserComponent,
    ChangeIndexModalComponent,
    ChangeBranchModalComponent,
    AddBriefLessonComponent,
    BriefLessonsListComponent,
    AddMaterialFilesComponent,
    MaterialFilesListComponent,
    AddFileModalComponent,
    SendToTopicComponent,
    SendToUsersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatChipsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    CKEditorModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    ClipboardModule,
    CKEditorModule,
    // Angular Material
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatExpansionModule,
    MatMenuModule,
    MatGridListModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatStepperModule,
    MatNativeDateModule,
    FileSaverModule,
    MatRadioModule,
    // MatNativeDateModule,
    ToastrModule.forRoot(),
    // Translation
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgChartsModule,
  ],
  // entryComponents: [
  //     ImageComponent
  // ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
