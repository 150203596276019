<h1 mat-dialog-title class="text-right">{{ "changeBranch" | translate }}</h1>
<div mat-dialog-content>
    <div class="container container-top-margin add-container" style="box-shadow: none">
      <div class="row">
        <div class="col">
          <mat-card class="add-card">
            <form [formGroup]="form">
              <div class="row text-center">
                <!-- <div class="col-12 margin-auto">
                  <mat-form-field class="full-width">
                    <input formControlName="branch" matInput placeholder="{{ 'branch' | translate }}" />
                  </mat-form-field>
                </div> -->
                <div class="col-12 margin-auto">
                    <mat-form-field class="full-width">
                        <mat-label> {{'branch' | translate}}</mat-label>
                        <mat-select formControlName='branch'>
                            <mat-option *ngFor="let b of branches" value='{{b}}'>
                                {{b | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
              </div>
              <div class="row text-center">
                <div class="col-12">
                  <button mat-raised-button (click)="changeBranch()" [disabled]="!form.valid" color="primary" >
                    <span>{{ "changeBranch" | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </mat-card>
        </div>
      </div>
    </div>
  </div>