import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Unit } from './../../../models/category';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { branches } from '../../../models/BaseURL';
import { ChangeIndexModalComponent } from '../../modals/change-index-modal/change-index-modal.component';

@Component({
  selector: 'app-units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.scss']
})
export class UnitsListComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  deletedForm: UntypedFormGroup;
  lessonsCats = [];
  searchWord = '';

  filterForm: UntypedFormGroup;
  data: Unit[] = [];
  editUrl: '/edit-unit/1';

  // types is [ text, img, date, inside-url, new-url, boolean, innerHtml, object, translated-text ]
  displayedColumns = [
    'id',
    'nameEn',
    'nameFr',
    'nameAr',
    'lessonsCat',
    'status',
    'active',
    'edit',
    // 'delete'
  ];

  tabelKeys = [
    { label: 'image', key: 'image', type: 'img' },
    { label: 'nameEn', key: 'nameEn', id: '_id', type: 'text' },
    { label: 'nameFr', key: 'nameFr', id: '_id', type: 'text' },
    { label: 'nameAr', key: 'nameAr', id: '_id', type: 'text' },
    { label: 'lessonsCat', key: 'lessCat', id: '_id', type: 'text' },
    { label: 'status', key: 'deleted', type: 'boolean' },
    { label: 'active', key: 'isActive', type: 'boolean' },
  ];
  dataSource: MatTableDataSource<Unit>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image = [];

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 150, 200];
  pageIndex;
  branches = branches;
  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog
  ) {
    this.getAllLessonsCats()
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      deleted: new UntypedFormControl('false', []),
      isActive: new UntypedFormControl('true', []),
      lessonsCat: new UntypedFormControl(null, []),
      branch: new UntypedFormControl(null, []),
    });
    this.activeForm = new UntypedFormGroup({
      isActive: new UntypedFormControl(null, [])
    });
    this.deletedForm = new UntypedFormGroup({
      deleted: new UntypedFormControl(null, [])
    });
    this.getData(1);
  }

  searchAds() {
    this.filterForm.patchValue({ searchWord: this.searchWord})
    this.getData(1);
  }

  filter() {
    // this.filterForm.get('deleted').setValue(this.filterForm.get('deleted').value ? this.filterForm.get('deleted').value === 'true' : this.filterForm.get('deleted').value);
    // this.filterForm.get('isActive').setValue(this.filterForm.get('isActive').value ? this.filterForm.get('isActive').value === 'true' : this.filterForm.get('isActive').value);
    this.getData(1);
  }
  clearFilter() {
    this.searchWord = '';
    this.filterForm.get('deleted').setValue(null);
    this.filterForm.get('isActive').setValue(null);
    this.filterForm.get('lessonsCat').setValue(null);
    this.filterForm.get('branch').setValue(null);
    this.filterForm.get('searchWord').setValue('');
    this.getData(1);
  }
  clearFilterSearchWord() {
    this.searchWord = '';
    this.filterForm.get('searchWord').setValue('');
    this.getData(1);
  }

  getAll() {
    this.filterForm.patchValue({
      deleted: null,
      isActive: null,
      lessonsCat: null,
      branch: null,
    })
    this.getData(1);
  }

  getData(event: any) {
    console.log('ssssss', );
    this.full.getAllUnit(event.pageIndex + 1 || 1, event.pageSize, this.filterForm.value).subscribe((data: any) => {
      console.log('data', data);
      if(data.status){
        this.data = data.data; 
        this.dataSource = new MatTableDataSource(data.data);
        // this.length = data.paging.count;
        this.length = data.paging.total;
        this.pageSize = data.paging.pageSize;
      }
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(id) {
    // let url = window.location.href.replace('less-cat-list', `edit-less-cat/${id}`);
    // window.open(url, '_blank')
    this.router.navigateByUrl(`edit-unit/${id}`);
  }

  addSliderForProduct(product) {
    localStorage.setItem('proId', product._id);
    localStorage.setItem('proType', product.type);
    this.router.navigateByUrl('/add-slider')
  }

  filterByUser(id: string) {
    this.filterForm.patchValue({
      user: id
    })
    this.getData(1)
  }

  openImage(image): void {
    this.image.push(image);
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: [...this.image]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = [];
    });
  }


  changeDeleted(unit: Unit, event: MatSlideToggleChange) {
    this.deletedForm.patchValue({
      deleted: event.checked
    });

    this.full.changeDeletedUnit(unit._id, this.deletedForm.value).subscribe((data: any) => {
      unit.deleted = event.checked;
      this.full.openSnackBar(this.translate.instant('adStatusChanges'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }
  changeActivated(unit: Unit, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      isActive: event.checked
    });

    this.full.changeDeletedUnit(unit._id, this.activeForm.value).subscribe((data: any) => {
      unit.isActive = event.checked;
      this.full.openSnackBar(this.translate.instant('adStatusChanges'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

  delete(id) {
    this.full.deleteOneProduct(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('articleDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  copy(val) {
    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = `https://ncrgrup.com/product/${val._id}`;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand('copy');
    // document.body.removeChild(selBox);
    // this.toastr.successToastr(this.translate.instant('linkCopied'));
  }
  getAllLessonsCats(){
    this.full.getAllLessonsCategoriesForDropdown(1, 500)
    .subscribe((response: any) => {
      this.lessonsCats = response.data;
    }, (error) => {
    });
  }


  changIndex(id, i) {
    console.log('asd', id);
    const dialogRef = this.dialog.open(ChangeIndexModalComponent, {
      data: {id: id, model: 'unites', index: i??0}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result === true){
        this.getData(1);
      }
    });
  }
}
