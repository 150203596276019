<div class="container-fluid table-container">
  <div class="row">
    <div class="col">
      <mat-card class="table-card">
        <div class="row text-center">
          <div class="col">
            <h3>{{ "lessCatList" | translate }}</h3>
          </div>
        </div>

        <div class="row add-item-row">
          <div class="col flex">
            <button
              mat-raised-button
              color="primary"
              (click)="goTo('add-less-cat')"
            >
              {{ "addNewLessCat" | translate }}
            </button>
            &nbsp; &nbsp; &nbsp;
          </div>
        </div>

        <div class="row text-center">
          <div class="col-lg-6 col-12">
            <mat-form-field class="example-form-field" style="width: 100%">
              <mat-label>{{ "search" | translate }}</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="searchWord"
                (input)="searchAds()"
              />
              <button
                *ngIf="searchWord"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearFilterSearchWord()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <form [formGroup]="filterForm">
              <div class="row">
                <div class="col-lg-2">
                  <mat-form-field class="full-width">
                      <mat-label> {{'branch' | translate}}</mat-label>
                      <mat-select formControlName='branch'>
                          <mat-option *ngFor="let b of branches" value='{{b}}'>
                              {{b | translate}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-2">
                  <mat-form-field class="full-width">
                    <mat-label> {{ "deleted" | translate }}</mat-label>
                    <mat-select formControlName="deleted">
                      <mat-option value="true">
                        {{ "deleted" | translate }}
                      </mat-option>
                      <mat-option value="false">
                        {{ "notDeleted" | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-2">
                  <mat-form-field class="full-width">
                    <mat-label> {{ "isActive" | translate }}</mat-label>
                    <mat-select formControlName="isActive">
                      <mat-option value="true">
                        {{ "active" | translate }}
                      </mat-option>
                      <mat-option value="false">
                        {{ "inActive" | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 t-a-c">
                  <button mat-raised-button (click)="filter()" color="primary">
                    <span>{{ "filterLessCat" | translate }}</span>
                  </button>
                </div>
                <div class="col-lg-2 t-a-c">
                  <button
                    mat-raised-button
                    (click)="clearFilter()"
                    color="primary"
                  >
                    <span>{{ "cleatAllFilter" | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <mat-spinner class="my-spinner" *ngIf="(isLoadingData$ | async)"></mat-spinner>
        <table *ngIf="!(isLoadingData$ | async)" mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "id" | translate }}
            </th>
            <td mat-cell *matCellDef="let row; let i = index">
              <!-- {{ i + 1 }} -->
              <span class="cp" (click)="changIndex(row._id, row.index)">{{row.index ?? 0}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="branch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "branch" | translate }}
            </th>
            <td class="cp" (click)="changeLessonCatBranch2(row._id, row.branch)" mat-cell *matCellDef="let row">{{row.branch?? 'none' | translate}}</td>
          </ng-container>

          <ng-container *ngFor="let t of tabelKeys" [matColumnDef]="t?.label">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ t?.label | translate }}
            </th>

            <td mat-cell *matCellDef="let row">
              <span *ngIf="t?.type === 'text'" [title]="row[t?.key]">
                {{ row[t?.key] | slice : 0 : 40 }} ..
              </span>
              <span *ngIf="t?.type === 'number'"> {{ row[t?.key] }} </span>

              <span *ngIf="t?.type === 'translated-text'">
                {{ row[t?.key] | translate }}
              </span>

              <span *ngIf="t?.type === 'date'">
                {{ row[t?.key] | date : "short" }}
              </span>

              <!-- <span *ngIf='t?.type === "object"'> {{row[t?.key][t?.child]}} </span> -->

              <span *ngIf="t?.type === 'boolean' && t?.key === 'isActive'">
                <mat-slide-toggle
                  color="primary"
                  [checked]="row[t?.key]"
                  (change)="changeActivated(row, $event)"
                >
                  <span class="green" [hidden]="row[t?.key] !== true">{{
                    "active" | translate
                  }}</span>
                  <span class="red" [hidden]="row[t?.key] !== false">{{
                    "inActive" | translate
                  }}</span>
                </mat-slide-toggle>
              </span>
              <span *ngIf="t?.type === 'boolean' && t?.key === 'deleted'">
                <mat-slide-toggle
                  color="primary"
                  [checked]="row[t?.key]"
                  (change)="changeDeleted(row, $event)"
                >
                  <span class="green" [hidden]="row[t?.key] !== true">{{
                    "deleted" | translate
                  }}</span>
                  <span class="red" [hidden]="row[t?.key] !== false">{{
                    "notDeleted" | translate
                  }}</span>
                </mat-slide-toggle>
              </span>

              <span *ngIf="t?.type === 'img'">
                <i
                  class="material-icons pointer"
                  (click)="openImage(row[t?.key])"
                >
                  visibility
                </i>
              </span>

              <span *ngIf="t?.type === 'innerHtml'" class="inner-span">
                <span [innerHtml]="row[t?.key] | slice : 0 : 15"></span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="deleted">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "deleted" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              <span>
                <mat-slide-toggle
                  color="primary"
                  [checked]="row?.deleted"
                  (change)="changeDeleted(row, $event)"
                >
                  <span class="green" [hidden]="row?.deleted === true">{{
                    "deleted" | translate
                  }}</span>
                  <span class="red" [hidden]="row?.deleted !== false">{{
                    "notDeleted" | translate
                  }}</span>
                </mat-slide-toggle>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "isActive" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              <span>
                <mat-slide-toggle
                  color="primary"
                  [checked]="row?.isActive"
                  (change)="changeActivated(row, $event)"
                >
                  <span class="green" [hidden]="row?.isActive === true">{{
                    "active" | translate
                  }}</span>
                  <span class="red" [hidden]="row?.isActive === false">{{
                    "inActive" | translate
                  }}</span>
                </mat-slide-toggle>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "edit" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              <a (click)="edit(row._id)">
                <i class="material-icons pointer"> edit </i>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "delete" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              <span (click)="confirmDialog(row._id)" class="pointer">{{
                "delete" | translate
              }}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
          #paginator
          [showFirstLastButtons]="true"
          [pageIndex]="0"
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = getData($event)"
        >
        </mat-paginator>
      </mat-card>
    </div>
  </div>
</div>
