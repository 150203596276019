<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{'sendFcm' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-center">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-12">
                            <div class="example-container">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="{{ 'usersList' | translate }}"
                                        (selectionChange)="selectPage($event)">
                                        <mat-option *ngFor="let p of dropdownPages; let i = index" [value]="i+1">{{
                                            i+1
                                            }} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="example-container">
                                <mat-form-field class="full-width example-full-width">
                                    <span>{{'title' | translate}}</span>
                                    <input matInput formControlName="title" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="example-container">
                                <mat-form-field class="full-width example-full-width">
                                    <span>{{'body' | translate}}</span>
                                    <textarea rows="5" matInput formControlName="body" type="text"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button color="warn" (click)="sendFcm()" [disabled]="!form.valid">
                                {{'sendFcm' | translate}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>