<h1 mat-dialog-title class="text-right">{{ "changeIndex" | translate }}</h1>
<div mat-dialog-content>
    <div class="container container-top-margin add-container" style="box-shadow: none">
      <div class="row">
        <div class="col">
          <mat-card class="add-card">
            <form [formGroup]="form">
              <div class="row text-center">
                <div class="col-12 margin-auto">
                  <mat-form-field class="full-width">
                    <input formControlName="index" min="0" matInput placeholder="{{ 'index' | translate }}" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-12">
                  <button mat-raised-button (click)="changeIndex()" [disabled]="!form.valid" color="primary" >
                    <span>{{ "changeIndex" | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </mat-card>
        </div>
      </div>
    </div>
  </div>