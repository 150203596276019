<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center">
              {{ "editSetings" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card class="add-card">
        <form [formGroup]="form">
          <mat-tab-group>
            <mat-tab label="{{ 'global' | translate }}">
              <div class="container" style="margin-top: 1rem">
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="phone"
                        matInput
                        placeholder="{{ 'phone' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="phone1"
                        matInput
                        placeholder="{{ 'phone' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="email"
                        matInput
                        placeholder="{{ 'email' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="email1"
                        matInput
                        placeholder="{{ 'email' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="appVersion"
                        matInput
                        placeholder="{{ 'appVersion' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="instagram"
                        matInput
                        placeholder="{{ 'instagram' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="twitter"
                        matInput
                        placeholder="{{ 'youtube' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'forceUpdate' | translate}}</mat-label>
                                            <mat-select formControlName='forceUpdate'>
                                                <mat-option value='true'>
                                                    {{'yes' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'no' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                  <!-- <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'canAdv' | translate}}</mat-label>
                                            <mat-select formControlName='canAdv'>
                                                <mat-option value='true'>
                                                    {{'yes' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'no' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="youtube"
                        matInput
                        placeholder="{{ 'youtube' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="facebook"
                        matInput
                        placeholder="{{ 'facebook' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col col-4">
                    <mat-form-field class="full-width">
                      <input type="number" min="0" formControlName="freeTrailDays" matInput placeholder="{{ 'freeTrailDays' | translate }}" />
                    </mat-form-field>
                  </div>
                  <div class="col col-4">
                    <div class="mat-mdc-form-field full-width">
                      <div class="mdc-text-field mat-mdc-text-field-wrapper">
                        <div class="mat-mdc-form-field-focus-overlay"></div>
                        <div class="mat-mdc-form-field-flex">
                          <mat-checkbox id="isAppSliderActive" class="example-margin" formControlName="isAppSliderActive" >{{ "isAppSliderActive" | translate }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col col-4">
                    <div class="mat-mdc-form-field full-width">
                      <div class="mdc-text-field mat-mdc-text-field-wrapper">
                        <div class="mat-mdc-form-field-focus-overlay"></div>
                        <div class="mat-mdc-form-field-flex">
                          <mat-checkbox id="discountStatus" class="example-margin" formControlName="discountStatus" >{{ "discountStatus" | translate }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row text-center" style="margin-top: 1rem">
                    <div class="col-8">
                      <mat-form-field class="full-width">
                        <mat-label>{{'examDate' | translate}}</mat-label>
                        <input formControlName="examDate" matInput [matDatepicker]="picker">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>                      
                    </div>
                    <div class="col-4">
                      <div class="mat-mdc-form-field full-width">
                        <div class="mdc-text-field mat-mdc-text-field-wrapper">
                          <div class="mat-mdc-form-field-focus-overlay"></div>
                          <div class="mat-mdc-form-field-flex">
                            <mat-checkbox id="isExamDateActive" class="example-margin" formControlName="isExamDateActive" >{{ "isExamDateActive" | translate }}</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </mat-tab>

            <!-- <mat-tab label="{{ 'arabic' | translate }}">
              <div class="container">
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="example-full-width">
                      <textarea
                        rows="5"
                        formControlName="addressAr"
                        matInput
                        placeholder="{{ 'address' | translate }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="example-full-width">
                      <textarea
                        rows="5"
                        formControlName="aboutFooterAr"
                        matInput
                        placeholder="{{ 'aboutFooter' | translate }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="aboutFirstTitleAr"
                        matInput
                        placeholder="{{ 'aboutFirstTitle' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <textarea
                        rows="5"
                        formControlName="aboutWebDesAr"
                        matInput
                        placeholder="{{ 'aboutFirstDes' | translate }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <span>{{ "aboutApp" | translate }}</span>
                    <ckeditor
                      formControlName="aboutAppAr"
                      [editor]="Editor"
                      [config]="config"
                    >
                    </ckeditor>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <span>{{ "condittions" | translate }}</span>
                    <ckeditor
                      formControlName="condittionsAr"
                      [editor]="Editor"
                      [config]="config"
                    >
                    </ckeditor>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <span>{{ "privacy" | translate }}</span>
                    <ckeditor
                      formControlName="privacyAr"
                      [editor]="Editor"
                      [config]="config"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'english' | translate }}">
              <div class="container">
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="example-full-width">
                      <textarea
                        rows="5"
                        formControlName="addressEn"
                        matInput
                        placeholder="{{ 'address' | translate }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="example-full-width">
                      <textarea
                        rows="5"
                        formControlName="aboutFooterEn"
                        matInput
                        placeholder="{{ 'aboutFooter' | translate }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="aboutFirstTitleEn"
                        matInput
                        placeholder="{{ 'aboutFirstTitle' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <textarea
                        rows="5"
                        formControlName="aboutWebDesEn"
                        matInput
                        placeholder="{{ 'aboutFirstDes' | translate }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">

                    <span>{{ "aboutApp" | translate }}</span>
                    <ckeditor
                      formControlName="aboutAppEn"
                      [editor]="Editor"
                      [config]="config"
                    >
                    </ckeditor>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">

                    <span>{{ "condittions" | translate }}</span>
                    <ckeditor
                      formControlName="condittionsEn"
                      [editor]="Editor"
                      [config]="config"
                    >
                    </ckeditor>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">

                    <span>{{ "privacy" | translate }}</span>
                    <ckeditor
                      formControlName="privacyEn"
                      [editor]="Editor"
                      [config]="config"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
            </mat-tab> -->
          </mat-tab-group>

          <div class="row text-center">
            <div class="col">
              <br />
              <br />
              <button
                mat-raised-button
                (click)="edit()"
                [disabled]="!form.valid"
                color="primary"
              >
                <span>{{ "editSetings" | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
